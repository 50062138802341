/* eslint-disable @typescript-eslint/camelcase */
import { Vue } from "vue-class-component";
import { unparse } from "papaparse";

export default class OutGoingMixin extends Vue {
  downloadCsvFailedStt(params: {
    fileName: string;
    listStt: Array<any>;
    withBag?: boolean;
  }) {
    const sttBlobData: Array<any> = params.listStt?.map((item: any) => {
      const sttNumber = item["stt_no"] ?? item["sttNo"];
      const bagNumber = item["bag_no"] ?? item["bagNo"];
      const bagVendorNo = item["bag_vendor_no"] ?? item["bagVendorNo"];
      // if withBag true, add column Bag Number
      if (params.withBag) {
        return [bagNumber, bagVendorNo, sttNumber];
      } else {
        return [sttNumber];
      }
    });

    const csvFields = params.withBag
      ? ["Bag Number", "External Bag Number", "STT Number"]
      : ["STT Number"];

    const csvContent = unparse({
      fields: csvFields,
      data: sttBlobData
    });
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    if ((navigator as any)?.msSaveBlob) {
      (navigator as any)?.msSaveBlob(blob, params.fileName);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", params.fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
